//  padding / grid=gap
@for $i from 1 to 21 {
    $side: $i * 10;

    //  padding
    .pad {
        &-tb-#{$side} {
            @include adaptiv-value("padding-top", $side, 50, 1);
            @include adaptiv-value("padding-bottom", $side, 50, 1);
        }

        &-t-#{$side} {
            @include adaptiv-value("padding-top", $side, 50, 1);
        }

        &-b-#{$side} {
            @include adaptiv-value("padding-bottom", $side, 50, 1);
        }
    }

    .g-gap {
        &-#{$side} {
            @if ($side >= 50) {
                @include adaptiv-value("grid-gap", $side, 20, 1);
            } @else if ($side < 50) {
                grid-gap: #{$side}px;
            }

            @include _1024 {
                grid-gap: 20px;
            }
        }
    }
}

.g-gap-ad-0 {
    @include _850 {
        grid-gap: 0;
    }
}

// radius
@for $i from 1 to 14 {
    $size: $i * 2;

    %rs {
        &-#{$size} {
            border-radius: #{$size}px;
        }
    }
}

// size
.vw-100 {
    width: 100vw;
}

.vh-100 {
    height: 100vh;
}

.pos-rel {
    z-index: 1;
    position: relative;
}

// any style
.over-hide {
    overflow: hidden;
}

.center {
    margin: 0 auto;
}
