.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
        content: "";

        display: flex;
        width: 100%;
        max-width: 20vw;
        height: 1px;
    }

    &::before {
        margin-right: 2vw;
        background: linear-gradient(to left, $accent, transparent);
    }

    &::after {
        margin-left: 2vw;
        background: linear-gradient(to right, $accent, transparent);
    }
}

// button
.btn {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: max-content;
    min-height: 44px;
    padding: 0.4rem 1.4em;

    border: 1px solid transparent;
    will-change: transform;

    color: $white;
    @extend %fs-18;
    font-family: $main-f;
    font-weight: $font-w-500;
    border-radius: 30px;

    overflow: hidden;
    user-select: none;
    transition: 0.3s background, 0.3s border-color, 0.3s color;
    transition-timing-function: ease;

    &.small {
        padding: 0.4rem 0.8em;
    }

    &.medium {
        padding: 0.4rem 1.8em;
    }

    &.large {
        padding: 0.4rem 2.2em;

        @include _768 {
            padding: 0.4rem 1.6em;
        }
    }

    &.full {
        width: 100%;
    }

    & svg {
        width: 24px;
        height: 24px;

        margin-left: 10px;

        transition: 0.3s fill ease;
    }

    &.transparent {
        background: transparent !important;
        color: inherit;
        border-color: inherit;
    }

    &.icon {
        position: relative;
        min-width: 260px;
        min-height: 52px;
        padding: 0.4rem 3.4em;

        @include _480 {
            min-width: 200px;
            padding: 0.4rem 2.4em;
        }

        @include _420 {
            min-width: max-content;
            padding: 0.4rem 1.5em;
        }

        & .btn__img {
            @include imgAdaptive(60px, null, 100, contain);
            position: absolute;
            left: 0;
            top: -50%;
            opacity: 0.5;

            transition: 0.3s all ease;
        }

        @media (hover) {
            &:hover {
                & .btn__img {
                    filter: invert(65%) sepia(25%) saturate(5229%) hue-rotate(359deg) brightness(103%) contrast(99%);
                }
            }
        }
    }

    &.white {
        background: $white;
        border-color: $white;
        color: $white;

        & svg {
            fill: $white;
        }

        &:not(.transparent) {
            color: $black;

            & svg {
                fill: $black;
            }

            @media (hover) {
                &:hover {
                    background: transparent !important;
                    border-color: $white;
                    color: $white;

                    & svg {
                        fill: $white;
                    }
                }
            }
        }

        @media (hover) {
            &:hover {
                background: $white !important;
                border-color: $white;
                color: $black;

                & svg {
                    fill: $black;
                }
            }
        }
    }

    &.black {
        background: $black;
        border-color: $black;
        color: $black;

        & svg {
            fill: $black;
        }

        &:not(.transparent) {
            color: $white;

            & svg {
                fill: $white;
            }

            @media (hover) {
                &:hover {
                    background: transparent !important;
                    border-color: $black;
                    color: $black;

                    & svg {
                        fill: $black;
                    }
                }
            }
        }

        @media (hover) {
            &:hover {
                background: $black !important;
                border-color: $black;
                color: $white;

                & svg {
                    fill: $white;
                }
            }
        }
    }

    &.accent {
        background: $accent;
        border-color: $accent;
        color: $accent;

        & svg {
            fill: $accent;
        }

        &:not(.transparent) {
            color: $white;

            & svg {
                fill: $white;
            }

            @media (hover) {
                &:hover {
                    background: $white !important;
                    border-color: $accent;
                    color: $accent;

                    & svg {
                        fill: $accent;
                    }
                }
            }
        }

        @media (hover) {
            &:hover {
                background: $accent !important;
                border-color: $accent;
                color: $white;

                & svg {
                    fill: $white;
                }
            }
        }
    }

    &.accent-5 {
        background: $accent-5;
        border-color: $accent-5;
        color: $accent-5;

        & svg {
            fill: $accent-5;
        }

        &:not(.transparent) {
            color: $white;

            & svg {
                fill: $white;
            }

            @media (hover) {
                &:hover {
                    background: $white !important;
                    border-color: $accent-5;
                    color: $accent-5;

                    & svg {
                        fill: $accent-5;
                    }
                }
            }
        }

        @media (hover) {
            &:hover {
                background: $accent-5 !important;
                border-color: $accent-5;
                color: $white;

                & svg {
                    fill: $white;
                }
            }
        }
    }
}
