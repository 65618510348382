// inputs
.input {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: max-content;
    transition: 0.3s all ease;

    &__label {
        width: max-content;
        margin-bottom: 10px;
        font-weight: $font-w-500;
        @extend %fs-18;
    }

    & input,
    & textarea {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 20px 20px;

        outline: none;
        border: 1px solid rgba($black, 0.1);

        background: transparent;
        border-radius: 8px;

        color: $black;
        font-size: 16px;

        transition: 0.3s all ease;

        &::placeholder {
            color: $black;
        }

        @media (hover) {
            &:hover {
                border-color: $accent-5;
            }
        }

        &:focus,
        &.active {
            border-color: $accent-5 !important;

            & .input__fake-field-icon {
                transform: rotate(90deg);
            }
        }
    }

    & textarea {
        height: 195px;
        resize: none;
    }
}
