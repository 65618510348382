#slider-round {
    height: 10px;

    & .noUi-handle {
        top: -12px;

        border: none;
        box-shadow: none;

        background: transparent;
        width: 30px;
        height: 30px;

        &::before,
        &::after {
            display: none;
        }
    }

    & .noUi-touch-area {
        border-radius: 50%;
        background: $calm;
        border: 4px solid $white;

        cursor: pointer;

        box-shadow: 0 5px 10px rgba($black, 0.5);
    }

    & .noUi-connect {
        background: $calm;
    }

    & .noUi-tooltip {
        bottom: 16px;
    }
}
