.switch {
    &__label {
        position: relative;
        display: flex;
        align-items: center;
        width: 50px;
        height: 20px;

        border: 1px solid $black;

        border-radius: 3.125rem;
    }

    &__input {
        width: 0;
        height: 0;
        opacity: 0;
        display: none;

        &:checked + .switch__content {
            transform: translateX(calc(100% + 16px));
            background: $calm;
        }
    }

    &__content {
        position: absolute;
        left: 2px;
        display: block;
        width: 14px;
        height: 14px;

        border-radius: 50%;
        background: $black;

        will-change: transform;
        transition: 0.3s all ease;
    }
}
