// select
.select-custom {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    padding: 0 20px;
    outline: none;
    border: 1px solid rgba($black, 0.1);
    border-radius: 8px;

    background: transparent;
    user-select: none;

    color: $black;
    font-size: 16px;

    cursor: pointer;

    transition: 0.3s all ease;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

        width: 14px;
        height: 14px;

        background-image: url('data:image/svg+xml, \
        <svg   viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg"> \
            <path d="M2.62268e-07 18.5L7.5 11L1.57361e-06 3.5L1.5 0.499999L12 11L1.5 21.5L2.62268e-07 18.5Z" fill="%23242e40"></path> \
        </svg>');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;

        transition: 0.3s all ease;
    }

    &.active {
        border-color: $accent-5;
        &::after {
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (hover) {
        &:hover {
            border-color: $accent-5;
        }
    }

    &__field {
        display: flex;
        align-items: center;

        width: 100%;
        height: 100%;
    }

    &__list {
        z-index: 10;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;

        display: flex;
        flex-direction: column;

        width: 100%;
        height: max-content;
        max-height: 200px;
        padding: 14px 10px;

        background: $white;
        border: 1px solid rgba($black, 0.1);
        border-radius: 8px;
        box-shadow: 0 8px 10px rgba($black, 0.1);

        opacity: 0;
        transform: translateY(-20px);
        pointer-events: none;
        visibility: hidden;

        transition: 0.3s all ease;

        &.active {
            pointer-events: painted;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }

        & ul {
            display: flex;
            flex-direction: column;

            width: 100%;

            overflow-y: auto;
            overscroll-behavior: contain;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 6px;
            }

            scrollbar-color: $black transparent;
            scrollbar-width: thin;

            transition: 0.3s all ease;
            & li {
                display: flex;

                width: 100%;
                padding: 0.4rem 1em;
                border-radius: 6px;

                color: $black;
                cursor: pointer;

                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }

                &.active {
                    color: $white;
                    background: $accent;
                }

                &:hover:not(.active) {
                    color: $white;
                    background: rgba($accent, 0.7);
                }
            }
        }
    }

    & select {
        display: none;
        opacity: 0;
        max-width: 0;
        max-height: 0;
        visibility: hidden;
    }
}
