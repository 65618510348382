@import "input-checkbox";
@import "input-switch";
@import "input-radio";
@import "select";
@import "input";
@import "input-file";
@import "input-range";
@import "input-error";

$form-gap: 20px;

.form {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    grid-gap: $form-gap;

    width: 100%;

    &-fr-1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        grid-gap: $form-gap;

        width: 100%;
        height: max-content;
    }

    &-fr-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        grid-gap: $form-gap;

        width: 100%;
        height: max-content;

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-fr-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        grid-gap: $form-gap;

        width: 100%;
        height: max-content;

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-fr-4 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        grid-gap: $form-gap;

        width: 100%;
        height: max-content;

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
