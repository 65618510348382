.accard {
    $border-color: rgba($black, 0.5);
    $bg: $black;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: max-content;

    &__item {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: max-content;

        border-bottom: 1px solid $accent-6;

        &:nth-child(1) {
            border-top: 1px solid $accent-6;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding: 20px 0;

        font-weight: $font-w-500;
        @extend %fs-22;
        text-align: left;

        transition: 0.3s all ease;

        &::after {
            content: "";

            display: flex;
            flex-shrink: 0;

            width: 50px;
            height: 50px;
            margin-left: 20px;
            border: 1px solid $accent-6;
            border-radius: 50%;

            background-image: url('data:image/svg+xml, \
            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="%23ccc"><path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>');
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
            pointer-events: none;

            will-change: transform;

            transform: rotate(45deg);

            transition: 0.3s all ease;
        }

        &.active {
            color: $accent-2;

            &::after {
                border-color: $accent;
                background: $accent;
                transform: rotate(90deg);

                background-image: url('data:image/svg+xml, \
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="%23fff"><path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>');
                background-position: center;
                background-size: 50%;
                background-repeat: no-repeat;
                pointer-events: none;
            }
        }

        @media (hover) {
            &:hover {
            }
        }
    }

    &__drop {
        display: block;

        width: 100%;
        max-height: 0;

        overflow: hidden;

        transition: 0.3s all ease;
    }

    &__drop-inner {
        display: flex;
        flex-direction: column;

        width: 100%;
        padding: 10px 0 30px 0;
    }
}
