// block
$content-width: 1440;

%block-tmp-row {
    position: relative;
    display: flex;
    justify-content: center;

    width: 100%;
}

%block-tmp-col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
}

// flex
.flex\:row {
    @extend %block-tmp-row;

    &-hd {
        @extend .flex\:row;
        max-width: 1920px;
    }

    &-max {
        @extend .flex\:row;
        max-width: #{$content-width + 40}px;
        padding: 0 20px;
    }
}

.flex\:col {
    @extend %block-tmp-col;

    &-hd {
        @extend .flex\:col;
        max-width: 1920px;
    }

    &-max {
        @extend .flex\:col;
        max-width: #{$content-width + 40}px;
        padding: 0 20px;
    }
}

// grid

.grid\:fr {
    display: grid;
    width: 100%;

    &-1 {
        @extend .grid\:fr;
        grid-template-columns: repeat(1, 1fr);

        &.max {
        }

        &.full {
        }
    }

    &-2 {
        @extend .grid\:fr;
        grid-template-columns: repeat(2, 1fr);

        @include _850 {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-3 {
        @extend .grid\:fr;
        grid-template-columns: repeat(3, 1fr);

        @include _979 {
            grid-template-columns: repeat(2, 1fr);
        }

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-4 {
        @extend .grid\:fr;
        grid-template-columns: repeat(4, 1fr);

        @include _1170 {
            grid-template-columns: repeat(2, 1fr);
        }

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
