.pagination {
    display: flex;

    & > * {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 38px;
        height: 38px;

        &:not(:last-child) {
            margin-right: 10px;
        }

        border: 1px solid $black;

        transition: 0.3s all ease;

        & svg {
            width: 24px;
            height: 24px;

            fill: $black;
            transition: 0.3s all ease;
        }

        @media (hover) {
            &:hover {
                background: $black;
                color: $white;

                & svg {
                    fill: $white;
                }
            }
        }

        &.active {
            background: $black;
            color: $white;
        }
    }
}
