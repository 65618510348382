//
.logo {
    display: flex;
    @include imgAdaptive(100px, null, 60, contain);
}
//
.decor-fly {
    z-index: -1;
    position: absolute;
    top: 80%;
    right: 0;

    display: flex;

    width: 40vw;
    height: 16vw;

    & img {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }
}

//
.modal-container {
    display: none;
}

.modal {
    display: flex;
    flex-direction: column;

    width: 100%;

    border-radius: 30px;
}

.modal-tour {
    max-width: 1500px;
    padding: 50px 10vw !important;

    background: $accent-4 !important;

    @include _979 {
        padding: 50px 20px !important;
    }

    &__inner {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    &__head {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 700px;

        margin: 0 auto;
        margin-bottom: 50px;

        & > * {
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        & ul {
            display: flex;
            flex-direction: column;

            align-items: center;
            & li {
                @extend %fs-22;
                font-weight: $font-w-700;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &:nth-child(1) {
                    color: $error;
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: flex-start;
                    text-align: left;
                    color: $accent;

                    & > * {
                        display: flex;

                        flex-shrink: 0;
                        width: 26px;
                        height: 26px;
                        margin-right: 10px;

                        background: $accent;
                        border-radius: 50%;

                        & img {
                            width: 100%;
                            height: 100%;

                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        width: 100%;

        @include _768 {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__content-item {
        display: flex;
        flex-direction: column;

        width: 100%;
        padding: 30px;

        border-radius: 30px;
        background: $white;

        box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);

        @include _420 {
            padding: 20px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 600px;

        margin: 0 auto;
        margin-top: 40px;
    }
}

.modal-near-date {
    max-width: 768px;
    padding: 30px 6vw 50px 6vw !important;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }

    &__head {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        margin-bottom: 30px;

        & > * {
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    & .form {
        max-width: 500px;
    }
}

//
