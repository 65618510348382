@mixin gen-fonts($nameFont, $fonts-list, $prefix) {
    @each $name, $font in $fonts-list {
        @font-face {
            font-family: "#{$nameFont}";
            font-display: swap;
            src: url("../fonts/#{$name}.ttf");
            font-display: block;
            font-style: normal;
            font-weight: #{$font};
        }
    }
    :root {
        --font-#{$prefix}: "#{$nameFont}";
        @each $name, $font in $fonts-list {
            --weight-#{$prefix}-#{$font}: #{$font};
        }
    }
}

@include gen-fonts(
    $nameFont: "Roboto",

    $fonts-list: (
        "Roboto-Bold": 700,
        "Roboto-Medium": 500,
        "Roboto-Regular": 400,
    ),
    $prefix: "m"
);

$main-f: var(--font-m);

$font-w-700: var(--weight-m-700);
$font-w-500: var(--weight-m-500);
$font-w-400: var(--weight-m-400);
