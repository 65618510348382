$gap: 24px;

.both {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    width: 100%;
    grid-gap: $gap;

    &.reverse {
        & > * {
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
        }
    }

    &.full {
        @include _1170 {
            grid-template-columns: repeat(1, 1fr);

            &.reverse {
                & > * {
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                    }
                }
            }
        }
    }

    &.max {
        @include _979 {
            grid-template-columns: repeat(1, 1fr);

            &.reverse {
                & > * {
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        width: 100%;
    }
}
