.header {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 10px 0;

    background: rgba($black, 0.3);
    backdrop-filter: blur(10px);

    & .burger-btn {
        z-index: 10;
        display: none;

        @include _1170 {
            display: flex;
            margin-left: 20px;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__logo {
    }

    &__menu {
        display: flex;
        align-items: center;

        margin-left: auto;
    }

    &__nav {
        display: flex;
        flex-direction: column;

        @include _1170 {
            display: none;
        }

        & ul {
            display: flex;

            & li {
                &:not(:last-child) {
                    margin-right: 26px;
                }

                @include _1170 {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            & a {
                display: flex;
                align-items: center;
                padding: 10px 15px;

                border: 1px solid transparent;
                border-radius: 30px;

                color: $white;

                transition: 0.3s all ease;
                @media (hover) {
                    &:hover {
                        border-color: rgba(235, 235, 235, 0.2);
                    }
                }
            }
        }
    }

    &__lang {
        display: flex;
        flex-direction: column;
        margin-left: 46px;

        & > ul {
            & > li {
                position: relative;

                &:hover {
                    & ul {
                        opacity: 1;
                        pointer-events: painted;
                        visibility: visible;
                    }
                }

                & > button {
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    color: $white;
                    border: 1px solid rgba(235, 235, 235, 0.2);
                    border-radius: 30px;

                    & svg {
                        width: 20px;
                        height: 20px;

                        fill: $white;
                    }

                    & img {
                        width: 25px;
                        height: 18px;
                        margin-right: 5px;

                        object-fit: contain;

                        border-radius: 3px;
                    }
                }
            }

            & ul {
                position: absolute;
                top: calc(100% + 10px);
                left: 0;

                display: flex;
                flex-direction: column;
                align-items: center;

                width: 100%;

                background: rgba($black, 0.3);
                border-radius: 14px;

                backdrop-filter: blur(10px);
                border: 1px solid rgba(235, 235, 235, 0.2);

                opacity: 0;
                pointer-events: none;
                visibility: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: calc(100%);
                    left: 0;

                    display: flex;

                    width: 100%;
                    height: 12px;
                }

                & li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    width: 100%;

                    overflow: hidden;
                    &:first-child {
                        border-radius: 14px 14px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 14px 14px;
                    }

                    & > * {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100%;
                        padding: 8px 15px;
                        color: $white;

                        & img {
                            width: 25px;
                            height: 18px;
                            margin-right: 5px;

                            object-fit: contain;

                            border-radius: 3px;
                        }

                        @media (hover) {
                            &:hover {
                                background: rgba(235, 235, 235, 0.2);
                            }
                        }
                    }
                }
            }
        }
    }

    & .social {
        margin-left: 10px;

        @include _600 {
            display: none;
        }
    }
}

//
.header-mob {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100vw;
    height: $height;

    background: rgba($black, 0.5);

    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    transition: 0.3s opacity ease;
    &.active {
        opacity: 1;
        pointer-events: painted;
        visibility: visible;
    }

    &__inner {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 500px;
        height: 100%;

        padding: 20px;

        background: $white;

        overflow-y: auto;
        @include customScroll(transparent, $accent);
    }

    &__lang {
        display: flex;
        flex-direction: column;

        width: 100%;

        margin-bottom: 50px;

        & ul {
            display: flex;

            & li {
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }

            & a {
                display: flex;
                align-items: center;
                padding: 4px 15px;

                border: 1px solid rgba($accent-7, 0.2);
                border-radius: 30px;

                font-weight: $font-w-500;
                color: $accent-7;

                & img {
                    width: 25px;
                    height: 18px;
                    margin-right: 5px;

                    object-fit: contain;

                    border-radius: 3px;
                }
            }
        }
    }

    //
    &__menu {
        display: flex;
        flex-direction: column;

        width: 100%;

        & ul {
            & li {
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }

            & a {
                font-weight: $font-w-500;
                color: $accent-7;
            }
        }
    }

    //
    &__contacts {
        display: flex;
        flex-direction: column;

        width: 100%;
        margin-top: auto;
    }

    &__btns {
        display: flex;
        flex-direction: column;

        margin-top: 10px;

        & > * {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}
