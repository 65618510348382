.radio-wrap {
    display: flex;
    justify-content: center;
    width: 100%;

    gap: 10px;
}

.radio {
    &.payment {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (hover) {
            &:hover {
                & .radio__img {
                    opacity: 1;
                }
            }
        }

        &::after {
            display: none;
        }
    }

    &__img {
        display: flex;

        width: 100%;
        height: 60px;
        border: 2px solid $accent-3;

        background: $white;
        @extend %rs-10;
        @extend .over-hide;

        opacity: 0.6;

        & img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }

    &__label {
        display: flex;
        align-items: center;
    }

    &__input {
        width: 0;
        height: 0;
        opacity: 0;

        &:checked + .radio__round {
            &::after {
                opacity: 1;
            }
        }

        &:checked + .radio__img {
            opacity: 1;
            border-color: $accent-3;
        }
    }

    &__round {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 20px;
        height: 20px;

        border-radius: 50%;

        border: 1px solid $black;

        transition: 0.3s all ease;

        &::after {
            content: "";

            width: 14px;
            height: 14px;

            background: $black;
            border-radius: 50%;

            opacity: 0;
            transition: 0.3s all ease;
        }
    }

    & p {
        margin-left: 10px;

        font-size: 16px;
        color: $black;

        transition: 0.3s all ease;
    }
}
