// arrow slider
.arrow-slider-container {
    display: flex;
}

.arrow-slider {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border: 1px solid $accent;
    background: $accent;

    border-radius: 50%;
    pointer-events: painted;

    box-shadow: 0px 8px 14px 0px rgba($accent, 0.5);

    overflow: hidden;
    transition: 0.3s background, 0.3s border-color;
    transition-timing-function: ease;

    & svg {
        width: 60%;
        height: 100%;

        fill: $white;
        transition: 0.3s transform, 0.3s fill;
        transition-timing-function: ease;
    }

    &:active {
        & svg {
            transform: scale(0.9);
        }
    }

    @media (hover) {
        &:hover {
            background: $white;
            border-color: $accent;

            & svg {
                fill: $accent;
            }
        }
    }

    &.arrow-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
