// variables

$white: #fff;
$black: #000;
$error: #eb4d4b;
$calm: #27ae60;

$height: calc(var(--vh, 1vh) * 100);

$accent: #fd9704;
$accent-2: #0b3ba7;
$accent-3: #4f4949;
$accent-4: #f9fbfc;
$accent-5: #145ce6;
$accent-6: #ccc;
$accent-7: #1b1b1f;
// background
.bg {
    &-black {
        background: $black;
    }

    &-error {
        background: $error;
    }

    &-calm {
        background: $calm;
    }

    &-accent-4 {
        background: $accent-4;
    }
}

.bg-fly {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


// filter: invert(27%) sepia(56%) saturate(2286%) hue-rotate(206deg) brightness(104%) contrast(108%);