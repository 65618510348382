.footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 30px 0;

    &__menu {
        display: flex;
        justify-content: space-between;

        width: 100%;
        padding-bottom: 24px;

        border-bottom: 1px solid $accent-2;

        @include _1170 {
            flex-direction: column;
            align-items: flex-start;
        }

        @include _600 {
            align-items: center;
        }
    }

    &__logo {
        @include _1170 {
            margin-bottom: 20px;
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;

        @include _1170 {
            margin-bottom: 20px;
        }

        & ul {
            display: flex;

            @include _1170 {
                flex-wrap: wrap;
            }

            @include _600 {
                justify-content: center;
            }

            & li {
                &:not(:last-child) {
                    margin-right: 46px;
                }

                @include _1170 {
                    margin-bottom: 10px;
                }
            }

            & a {
                color: $accent-7;

                @media (hover) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;

        & .text-tmp {
            margin-bottom: 10px;
            @include _600 {
                text-align: center;
            }
        }

        & .social {
            @include _600 {
                justify-content: center;
            }
        }
    }

    &__contacts-list {
        display: flex;
        flex-direction: column;

        margin-bottom: 10px;

        @include _600 {
            align-items: center;
        }
        & ul {
            display: flex;
            flex-direction: column;

            @include _600 {
                align-items: center;
            }

            & li {
                &:not(:last-child) {
                    margin-bottom: 6px;
                }
            }

            & a {
                display: flex;
                align-items: center;

                color: $accent-7;

                @include _600 {
                    text-align: center;
                }

                & svg {
                    margin-right: 8px;
                }

                @media (hover) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__copy {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding-top: 24px;

        @include _600 {
            flex-direction: column;
            align-items: center;
        }

        & a {
            color: $accent-7;

            @include _600 {
                text-align: center;
            }

            & * {
                font-style: normal;
                color: $accent;
            }

            @media (hover) {
                &:hover {
                    & * {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
