// social
.social {
    display: flex;

    &.theme-white {
        & li {
        }

        & a {
            background: $white;
            border-color: $white;

            box-shadow: none;

            & img {
            }
        }
    }

    & ul {
        display: flex;

        & li {
            &:not(:last-child) {
                margin-right: 4px;
            }
        }

        & a {
            display: flex;

            width: 38px;
            height: 38px;
            padding: 10px;

            border: 1px solid $white;
            border-radius: 50%;
            background: $white;
            box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.6);

            transition: 0.3s all ease;
            & img {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }

            @media (hover) {
                &:hover {
                    border-color: $accent;
                    & img {
                        filter: invert(65%) sepia(25%) saturate(5229%) hue-rotate(359deg) brightness(103%) contrast(99%);
                    }
                }
            }
        }
    }
}
