.card-work {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    background: $white;
    padding: 30px 30px 0 30px;
    overflow: hidden;

    transition: 0.3s all ease;

    &:nth-child(4n) {
        border-radius: 0px 30px 30px 0px;
    }

    &:nth-child(4n + 1) {
        border-radius: 30px 0px 0px 30px;
    }

    @include _1170 {
        &:nth-child(2n) {
            border-radius: 0px 30px 30px 0px;
        }

        &:nth-child(2n + 1) {
            border-radius: 30px 0px 0px 30px;
        }
    }

    @include _600 {
        border-radius: 30px !important;
        padding: 30px 20px 0 20px !important;
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50px;
        height: 50px;
        margin-bottom: 40px;

        border-radius: 50%;

        background: $accent-5;
        box-shadow: 0px 10px 20px 0px rgba(11, 59, 167, 0.4);

        color: $white;
        transition: 0.3s all ease;
    }

    &__info {
        display: flex;
        flex-direction: column;

        width: 100%;
        padding-bottom: 140px;

        @include _979 {
            padding-bottom: 100px;
        }

        @include _600 {
            padding-bottom: 60px;
        }
    }

    &__icon {
        z-index: 1;
        position: absolute !important;
        bottom: 0;
        left: -10%;

        @include imgAdaptive(100%, 160px, 100, contain);

        opacity: 0;
        transition: 0.3s all ease;
    }

    @media (hover) {
        &:hover {
            background: $accent-5;

            & .text-tmp {
                & > * {
                    color: $white;
                }
            }

            & .card-work__number {
                background: $white;

                color: $accent;
            }

            & .card-work__icon {
                opacity: 0.2;
            }
        }
    }
}

//

.card-tour {
    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: hidden;

    overflow: hidden;
    border-radius: 30px 0px;

    background: $white;
    box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);

    &__slider {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;

        user-select: none;

        & .swiper {
            cursor: zoom-in;
        }
        & .swiper-slide {
            overflow: hidden;
        }

        cursor: pointer;

        @media (hover) {
            &:hover {
                & .card-tour__slider-arrows {
                    opacity: 1;
                }
            }
        }
    }

    &__img {
        @include imgAdaptive(100%, null, 76, cover);
    }

    &__slider-arrows {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: 100%;

        padding: 20px;
        pointer-events: none;

        opacity: 0;
        transition: 0.3s opacity ease;

        @include _768 {
            opacity: 1;
            padding: 10px;
        }
    }

    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;
        border-radius: 50%;

        border: 1px solid $accent-4;
        box-shadow: 0 6px 10px rgba($black, 0.1);
        background: rgba($black, 0.3);
        backdrop-filter: blur(5px);

        pointer-events: painted;

        will-change: transform;
        transition: 0.2s all ease;

        &:active {
            transform: scale(1.1);
        }

        &.arrow-disabled {
            opacity: 0.5;

            cursor: not-allowed;
        }
        & svg {
            width: 60%;
            height: 60%;

            fill: $white;
        }
    }

    & .slider-pagination {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 100%;
        height: 100%;

        padding: 20px;
        pointer-events: none;
        & > * {
            display: flex;
            width: 6px;
            height: 6px;

            &:not(:last-child) {
                margin-right: 6px;
            }

            border-radius: 50%;
            background: $accent-6;

            &.active {
                background: $white;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        width: 100%;
        flex-grow: 1;
        padding: 20px;

        @include _768 {
            padding: 20px 10px;
        }
        & ul {
            margin-bottom: 50px;
            & li {
                &:nth-child(1) {
                    margin-bottom: 10px;
                    @extend %fs-22;
                    font-weight: $font-w-700;
                    color: $accent-7;
                }

                &:nth-child(2) {
                    margin-bottom: 20px;
                    @extend %fs-22;
                    font-weight: $font-w-500;
                    color: $accent-2;
                }

                &:nth-child(3) {
                    display: flex;
                    align-items: flex-start;

                    & > * {
                        display: flex;

                        width: 26px;
                        height: 26px;
                        margin-right: 10px;

                        background: $accent;
                        border-radius: 50%;

                        & img {
                            width: 100%;
                            height: 100%;

                            object-fit: contain;
                        }
                    }
                }
            }
        }

        & .btn {
            margin-top: auto;
        }
    }
}

//
.card-hotel {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;

    border-radius: 30px 0px;

    background-color: $white;
    box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);

    & .slider-pagination {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 100%;
        height: 100%;

        padding: 20px;
        pointer-events: none;
        & > * {
            display: flex;
            width: 6px;
            height: 6px;

            &:not(:last-child) {
                margin-right: 6px;
            }

            border-radius: 50%;
            background: $accent-6;

            &.active {
                background: $white;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        width: 100%;

        padding: 20px;
    }

    &__info-inner {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    &__info-head {
        display: flex;
        align-items: center;

        width: 100%;

        margin-bottom: 14px;
    }

    &__info-icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        margin-left: 20px;

        @extend %fs-22;
        font-weight: $font-w-500;
        & svg {
            width: 24px;
            height: 24px;
            margin-right: 4px;

            fill: $accent;
        }
    }

    &__info-locations {
        display: flex;
        align-items: center;

        width: max-content;

        margin-bottom: 14px;
        padding: 2px 16px;
        border-radius: 30px;

        background: $accent-5;

        color: $white;

        & svg {
            width: 24px;
            height: 24px;

            fill: $white;
        }
    }
}
