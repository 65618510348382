.main {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    flex-grow: 1;

    overflow: hidden;
}

.intro {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: $height;
    min-height: 600px;

    &__slider {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        & .swiper-slide {
            overflow: hidden;
        }
    }

    &__slide {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        height: 100%;
    }

    &__slide-img {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background: rgba($black, 0.2);
        }

        & img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__slide-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        max-width: #{$content-width + 40}px;
        padding: 0 20px;

        height: 100%;

        & .text-tmp {
            max-width: 680px;
            margin-bottom: 50px;
        }
    }

    &__ui {
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        width: 100%;
        height: 100%;
        padding: 50px 0;

        pointer-events: none;
    }

    & .pagination-intro {
        display: flex;

        & > * {
            display: flex;
            width: 140px;
            height: 6px;

            @include _979 {
                width: 50px;
            }
            &:not(:last-child) {
                margin-right: 10px;
            }

            border-radius: 6px;
            background: $accent-4;

            transition: 0.3s all ease;
            &.active {
                background: $accent;
            }
        }
    }
}

//
.near-date {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: calc(100% / 4 - 10px);
        min-width: 300px;
        margin: 5px;

        padding: 40px;
        border-radius: 30px;

        background: $white;
        box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);

        @include _768 {
            width: 100%;
            max-width: 200px;
            min-width: 100px;

            padding: 20px 0;
        }

        @include _600 {
            max-width: 150px;
        }

        @include _420 {
            max-width: 120px;
        }
    }

    &__card-img {
        @include imgAdaptive(60px, null, 100, contain);
        margin-bottom: 30px;

        @include _600 {
            @include imgAdaptive(40px, null, 100, contain);
        }
    }

    &__card-info {
    }
}

//
.hotel-gallery {
    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: hidden;
    &__item {
        display: flex;

        width: 100%;
        margin-bottom: 30px;

        @include _1170 {
            justify-content: center;
        }

        @include _979 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
        }

        @include _600 {
            grid-template-columns: repeat(1, 1fr);
        }

        & .card-hotel {
            &:nth-of-type(1) {
                flex-direction: row;
                margin-right: 30px;

                & .swiper {
                    width: 100%;
                    height: 100%;
                }

                & .card-tour__slider {
                    width: 50%;
                }

                & .card-tour__img {
                    display: flex;

                    width: 100%;
                    height: 100%;

                    & img {
                        width: 100%;
                        height: 100%;

                        object-fit: cover;
                    }

                    &::before {
                        display: none;
                    }
                }

                @include _1170 {
                    flex-direction: column;
                    max-width: calc(100% / 3 - 20px);

                    & .card-tour__slider {
                        width: 100%;
                    }

                    & .card-tour__img {
                        display: flex;

                        width: 100%;
                        height: 100%;

                        @include imgAdaptive(100%, null, 76, cover);
                    }
                }
            }

            &:nth-of-type(2) {
                max-width: calc(100% / 3 - 20px);
            }

            @include _979 {
                max-width: 100% !important;
            }
        }
    }
}

//
.gallery {
    display: grid;
    grid-template-areas:
        "a a b c"
        "a a d f";

    width: 100%;
    grid-gap: 10px;

    @include _600 {
        grid-template-areas:
            "a a"
            "a a"
            "b c"
            "d f";
    }

    &:nth-child(even) {
        grid-template-areas:
            "b c a a"
            "d f a a";

        @include _600 {
            grid-template-areas:
                "a a"
                "a a"
                "b c"
                "d f";
        }
    }

    &__img {
        @include imgAdaptive(100%, null, 100, cover);

        cursor: pointer;
        overflow: hidden;

        & img {
            will-change: transform;
            transition: 0.3s all ease;
        }

        @media (hover) {
            &:hover {
                & img {
                    transform: scale(1.05);
                }
            }
        }
        &:nth-child(1) {
            grid-area: a;
        }

        &:nth-child(2) {
            grid-area: b;
        }

        &:nth-child(3) {
            grid-area: c;
        }

        &:nth-child(4) {
            grid-area: d;
        }

        &:nth-child(5) {
            grid-area: f;
        }
    }
}

//
.decor-img {
    display: flex;

    width: 100%;
    min-height: 500px;
}

//
.reviews {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: 20px 20px 50px 20px;

    overflow: hidden;

    @include _600 {
        padding: 0;
        padding-bottom: 30px;
    }

    &__slider {
        display: flex;
        flex-direction: column;

        width: 100%;
        margin-bottom: 30px;

        border-radius: 30px;
        background: $white;
        box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);
    }

    &__slide {
        display: flex;
        justify-content: space-between;

        width: 100%;
        max-height: 600px;
        padding: 20px;

        overflow: hidden;

        @include _768 {
            flex-direction: column;

            max-height: 100vh;
        }
    }

    &__slide-media {
        z-index: 1;
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 450px;
        margin-right: 20px;

        @include _768 {
            margin: 0;
            margin-bottom: 20px;

            max-width: 350px;
        }

        & .text-tmp {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__social {
        display: flex;
        flex-direction: column;

        width: 100%;

        & ul {
            display: flex;

            & li {
                &:not(:last-child) {
                    margin-right: 4px;
                }
                & a {
                    display: flex;

                    width: 32px;
                    height: 32px;
                    padding: 8px;

                    border: 1px solid $white;
                    border-radius: 50%;
                    background: $white;
                    box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.5);

                    transition: 0.3s all ease;
                    & img {
                        width: 100%;
                        height: 100%;

                        object-fit: contain;
                    }

                    @media (hover) {
                        &:hover {
                            border-color: $accent;
                            & img {
                                filter: invert(65%) sepia(25%) saturate(5229%) hue-rotate(359deg) brightness(103%)
                                    contrast(99%);
                            }
                        }
                    }
                }
            }
        }
    }

    &__slide-img {
        @include imgAdaptive(100%, null, 100, cover);

        border-radius: 30px 0px;

        overflow: hidden;
    }

    &__slide-info {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 850px;
        overflow-y: scroll;
        @include customScroll(transparent, $accent);
        padding-right: 10px;

        @include _979 {
            margin: 0;
            margin-bottom: 20px;
        }
    }

    &__arrows {
        display: flex;

        & > * {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}

//
.contacts {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 100px 50px;

    &.as-page {
        align-items: center;
        justify-content: center;

        height: 100vh;
        padding: 100px 0;

        & .contacts__bg-full {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            width: 100%;
            height: 100%;

            & img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }
    }

    @include _600 {
        padding: 50px 20px;
    }

    @include _420 {
        padding: 30px 10px;
    }
    &__bg {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 30px;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;

        width: 100%;
        margin-bottom: -100px;

        & .social {
            margin-top: 10px;
        }
    }

    &__content-info {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 700px;
        height: max-content;
        padding: 30px 50px;
        margin-bottom: 100px;

        background: $white;
        border-radius: 30px;

        box-shadow: 0px 4px 20px 0px rgba(193, 203, 225, 0.3);

        @include _600 {
            padding: 30px 20px;
        }
        & .btn {
            margin: 20px 0;
        }
    }

    &__content-btns {
        display: flex;

        @include _850 {
            flex-direction: column;
            margin: 20px 0;
        }

        & .btn {
            @include _850 {
                margin: 0 0 10px 0 !important;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &__content-img {
        display: flex;
        margin-top: auto;

        width: 100%;
        max-width: 360px;
        max-height: 500px;

        margin-right: 50px;

        @include _1170 {
            display: none;
        }
    }
}

//
.telegram {
    z-index: 1;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 600px;
    overflow: hidden;

    @include _480 {
        min-height: 500px;
    }

    &::after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgba(20, 92, 230, 0.7);
    }

    &__bg {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        width: 100%;
        height: 100%;

        & img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__inner {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__info {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 600px;

        & .btn {
            margin-top: 40px;
        }
    }

    &__icon {
        position: relative;
        display: flex;

        @include _850 {
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;

            opacity: 0.3;
        }
        & svg {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            animation: 5s animRotete linear infinite;

            &:nth-child(2) {
                animation-direction: reverse;
            }
        }

        & img {
            width: 300px;
            height: 300px;

            object-fit: contain;
        }
    }
}

@keyframes animRotete {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

//
.page-error {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    &::after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgba(20, 92, 230, 0.6);
    }

    &__bg {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        width: 100%;
        height: 100%;

        & img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__info {
        display: flex;

        justify-content: space-between;
        align-items: center;

        width: 100%;

        @include _1024 {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        & .text-tmp {
            margin-bottom: 20px;
        }
    }

    &__info-inner {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    &__word {
        @include adaptiv-value("font-size", 400, 50, 1);
        color: #bedaea;
    }
}
