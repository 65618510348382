// ошибка
.input-error {
    & input,
    & textarea {
        background: rgba($error, 0.1);
        border-color: $error;
        color: $error;

        &::placeholder {
            color: $error !important;
        }
    }

    &.checkbox__label {
        & .checkbox__content {
            background: rgba($error, 0.1);
            border-color: $error;
        }

        & * {
            color: $error;
        }
    }

    &.switch__label {
        background: rgba($error, 0.1);
        border-color: $error;
    }

    &.file__label {
        background: rgba($error, 0.1);
        border-color: $error;

        & p {
            color: $error;
        }
    }

    & .input__label {
        color: $error;
    }
}

.text-empty {
    z-index: 5;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 20px;
    background: $error;

    border-top-left-radius: 0;
    border-radius: 4px;
    user-select: none;

    color: $white;
    font-weight: lighter;
    font-size: 14px;

    will-change: transform;
    pointer-events: none;
    filter: drop-shadow(0px 8px 10px rgba($black, 0.4));

    animation: animTultipForm 0.4s both ease;

    &:after {
        content: "";
        position: absolute;
        bottom: calc(100%);
        left: 50%;
        transform: translateX(-50%);

        border: 5px solid transparent;
        border-bottom: 5px solid $error;
    }
}

// прелоадер формы
.form-preloader {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: $white;

    font-size: 18px;
    color: $black;
    border: 1px solid rgba($black, 0.1);

    &__round {
        width: 40px;
        height: 40px;

        margin: 0;

        border-top: 4px solid $black;
        border-right: 4px solid transparent;
        border-radius: 50%;
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
    }

    &__text {
        animation: animTextAnsver 0.4s both ease;
    }
}

@keyframes animTultipForm {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes animTextAnsver {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
