body {
    font-family: $main-f;
    font-size: 1rem;
}

//
%title-tmp {
    font-family: $main-f;
    font-weight: $font-w-700;
    line-height: 120%;
    color: $accent-7;
}

%text-tmp {
    font-family: $main-f;
    font-weight: $font-w-400;
    line-height: 140%;
    @extend %fs-16;
    color: $accent-3;
}

//
h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %title-tmp;
}

li,
p {
    @extend %text-tmp;
}

// font size px
@for $index from 8 to 36 {
    $size: $index * 2;

    %fs {
        &-#{$size} {
            @if ($size >= 40) {
                @include adaptiv-value("font-size", $size, calc($size / 1.8), 1);
            } @else if ($size < 40 and $size > 34) {
                @include adaptiv-value("font-size", $size, calc($size / 1.6), 1);
            } @else if ($size <= 34 and $size > 30) {
                @include adaptiv-value("font-size", $size, calc($size / 1.55), 1);
            } @else if ($size <= 30 and $size > 18) {
                @include adaptiv-value("font-size", $size, 20, 1);
            } @else if ($size <= 18 and $size > 16) {
                @include adaptiv-value("font-size", $size, 18, 1);
            } @else if ($size == 16) {
                @include adaptiv-value("font-size", $size, 16, 1);
            }
        }
    }

    .text-tmp.title {
        &-#{$size} {
            & > h1,
            & > h2,
            & > h3,
            & > h4,
            & > h5,
            & > h6 {
                @extend %fs-#{$size};
            }
        }
    }

    .text-tmp.title-dec {
        &-#{$size} {
            & > h1,
            & > h2,
            & > h3,
            & > h4,
            & > h5,
            & > h6 {
                &:not(:first-child) {
                    @extend %fs-#{$size};
                }
            }
        }
    }

    .text-tmp.txt {
        &-#{$size} {
            & p {
                @extend %fs-#{$size};
            }
        }
    }
}

// шаблон текста
.text-tmp {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.color-white {
        & > * {
            color: $white;
        }
    }

    &.title-tt-up {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            text-transform: uppercase;
        }
    }

    &.all-center {
        text-align: center;
    }

    &.marg-all-10 {
        & > * {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &.txt-w-700 {
        & p {
            font-weight: $font-w-700;
        }
    }

    &.head {
        align-items: center;
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            max-width: 740px;
            color: $accent-2;
            margin-bottom: 20px !important;

            & > * {
                color: $accent;
            }

            &::after {
                content: "";

                display: flex;

                width: 100%;
                max-width: 80px;
                height: 4px;
                margin: 20px auto 0 auto;

                border-radius: 6px;

                background: $accent;
            }
        }
    }

    &.calm {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            color: $calm;
        }

        & li {
            display: flex;
            list-style: none;

            &::before {
                content: "";
                display: flex;
                flex-shrink: 0;

                width: 20px;
                height: 20px;

                margin-top: 2px;
                margin-right: 6px;

                background-image: url('data:image/svg+xml, \
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="%2327ae60"> \
                <path d="M294-242 70-466l43-43 181 181 43 43-43 43Zm170 0L240-466l43-43 181 181 384-384 43 43-427 427Zm0-170-43-43 257-257 43 43-257 257Z"/> \
                </svg>');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;

                transition: 0.3s all ease;
            }
        }
    }

    &.danger {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            color: $error;
        }

        & li {
            display: flex;
            list-style: none;

            &::before {
                content: "";
                display: flex;
                flex-shrink: 0;

                width: 20px;
                height: 20px;

                margin-top: 2px;
                margin-right: 6px;

                background-image: url('data:image/svg+xml, \
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="%23eb4d4b"> \
                <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/> \
                </svg>');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;

                transition: 0.3s all ease;
            }
        }
    }

    &.accent {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            color: $accent-3;

            & > * {
                color: $accent-2;
            }
        }

        & li {
            display: flex;
            list-style: none;

            &::before {
                content: "";
                display: flex;
                flex-shrink: 0;

                width: 20px;
                height: 20px;

                margin-top: 2px;
                margin-right: 6px;

                background-image: url('data:image/svg+xml, \
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="%23fd9704"> \
                <path d="M294-242 70-466l43-43 181 181 43 43-43 43Zm170 0L240-466l43-43 181 181 384-384 43 43-427 427Zm0-170-43-43 257-257 43 43-257 257Z"/> \
                </svg>');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;

                transition: 0.3s all ease;
            }
        }
    }

    &.txt-accent {
        & p,
        & li {
            color: $accent;
        }
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        &:not(:last-child) {
            margin-bottom: 0.8em;
        }
    }

    & p {
        &:not(:last-child) {
            margin-bottom: 1.8em;
        }

        & > a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    & ul,
    & ol {
        &:not(:last-child) {
            margin-bottom: 0.8em;
        }

        & li {
            width: 100%;
            list-style: disc;
            list-style-position: inside;

            & > a {
                color: $black;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: 0.5em;
            }
        }
    }

    & img,
    & video {
        width: 100%;
        height: auto;

        &:not(:last-child) {
            margin-bottom: 0.8em;
        }

        object-fit: cover;
    }

    & iframe {
        width: 100%;
        min-height: 600px;

        &:not(:last-child) {
            margin-bottom: 0.8em;
        }

        object-fit: cover;
    }

    & .table-wrapper {
        width: 100%;

        overflow-x: auto;

        &:not(:last-child) {
            margin-bottom: 0.8em;
        }

        & table {
            width: 100% !important;
            min-width: 1024px;

            & tr {
                &:nth-child(odd) {
                    background-color: rgba($black, 0.1);
                }
            }

            & td {
                padding: 10px;
                background: transparent !important;

                font-family: $main-f;
                & * {
                    font-family: $main-f;
                    background: transparent !important;
                }
            }
        }
    }
}
